
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({})
export default class LndTelInput extends Vue {
  @Prop({
    required: false,
    default: false,
  })
  formError!: boolean

  @Prop({
    required: false,
    default: false,
  })
  phoneExists!: boolean

  public country = 'ae'
  // public code = ''
  // public phone = ''
  public iti: any | null = null
  // public isValid: boolean | null = null

  public data = {
    code: '',
    phone: '',
    fullNumber: '',
    isValid: null,
  }

  @Watch('data', { deep: true })
  public checkData(val, oldVal) {
    // console.debug('%c checkData(val: %s, oldVal: %s)', 'color:magenta;', JSON.stringify(val, null, 4), JSON.stringify(oldVal, null, 4))

    this.changeCountry()
    this.$emit('update:data', this.data)
  }

  public changeCountry() {
    const countryData = this.iti.getSelectedCountryData()
    this.data.code = countryData?.dialCode
    this.data.fullNumber = this.iti.getNumber()
    this.data.isValid = this.iti.isValidNumber()
    // console.debug('%c changeCountry(data: %s)', 'color:gold;font-size:18px;', JSON.stringify(this.data, null, 4))
  }

  public startTelInput() {
    const input = document.querySelector('#phone')
    // @ts-ignore
    if (input && window && window.intlTelInput) {
      // @ts-ignore
      this.iti = window.intlTelInput(input, {
        initialCountry: this.country, // 'ae',
        strictMode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.1.0/build/js/utils.js',
      })
      input.addEventListener('countrychange', this.changeCountry)

      // Необходимо запустить, чтобы установить код страны
      this.changeCountry()
    }
  }

  public mounted() {
    console.debug('%c LndTelInput is mounted!', 'color:aqua;font-size:18px;')
    this.startTelInput()
  }
}
