
import { Component, Vue } from 'vue-property-decorator'
import LndHeader from '@/components/lnd/Header.vue'
import LndFooter from '@/components/lnd/Footer.vue'
import LndWelcome from '@/components/lnd/Welcome.vue'
import LndAbout from '@/components/lnd/About.vue'
import LndNode from '@/components/lnd/Node.vue'
import LndFuture from '@/components/lnd/Future.vue'
import LndSpecs from '@/components/lnd/Specs.vue'
import LndPart from '@/components/lnd/Part.vue'
import LndServices from '@/components/lnd/Services.vue'
import LndHorizon from '@/components/lnd/Horizon.vue'
import LndManagement from '@/components/lnd/Management.vue'
import LndSubscribe from '@/components/lnd/Subscribe.vue'
import LndPopupCheckout from '@/components/lnd/PopupCheckout.vue'
import LndPopupLogin from '@/components/lnd/PopupLogin.vue'
import LndScripts from '@/components/lnd/Scripts.vue'
import LndPopupResetPassword from '@/components/lnd/PopupResetPassword.vue'
import LndMobileMenu from '@/components/lnd/MobileMenu.vue'
import NodeDescription from '@/components/lnd/NodeDescription.vue'
import { readShowMobileMenu } from '@/store/main/getters'

@Component({
  methods: { readShowMobileMenu },
  components: {
    LndScripts,
    LndPopupCheckout,
    LndPopupLogin,
    LndPopupResetPassword,
    LndHeader,
    LndFooter,
    LndWelcome,
    LndAbout,
    LndNode,
    LndFuture,
    LndSpecs,
    LndPart,
    LndServices,
    LndHorizon,
    LndManagement,
    LndSubscribe,
    LndMobileMenu,
    NodeDescription,
  },
})
export default class Landing extends Vue {
  public showPopupLogin = false
  public showPopupResetPassword = false
  public showMobileMenu = false

  public isPopupCheckoutActive: boolean = false
  public fixed: boolean = true

  public showMobileLogin() {
    console.debug('%c showMobileLogin', 'color:lime;font-size:24px;')
    this.showMobileMenu = false
    this.showPopupLogin = true
  }

  public showReset() {
    this.showPopupLogin = false
    this.showPopupResetPassword = true
  }

  public openPopupCheckout () {
    this.isPopupCheckoutActive = true
  }

  public closePopupCheckout () {
    this.isPopupCheckoutActive = false
  }
}
