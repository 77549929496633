<template>
  <section
    id="node"
    class="container section-stat section grid-two-columns"
  >
    <div class="grid-two-columns__col-title section-stat__col-title">
      <h2 class="section__title section-stat__title">
        {{$t('lnd.node.title') }}
      </h2>
      <p class="section__subtitle section-stat__subtitle">
        {{$t('lnd.node.subtitle') }}
      </p>
    </div>
    <div class="grid-two-columns__col-content section-stat__col-content">
      <ul class="section-stat__list">
        <li class="section-stat__item">
          <h3 class="section-stat__list-val">

            <!-- {{$t('lnd.node.t1') }} -->
             <div style="display: flex; flex-direction: column;">
              <span>{{ storageSize }} PB</span>
              <small style="font-size: 12px;">1 Pb ({{ $t('petabyte') }}) = 1024 Tb</small>
             </div>
          </h3>
          <p class="section-stat__list-desc">
            {{$t('lnd.node.d1') }}
          </p>
        </li>
        <li class="section-stat__item">
          <h3 class="section-stat__list-val">
            <!-- {{$t('lnd.node.t2') }} -->
             {{ onlineCount }}
          </h3>
          <p class="section-stat__list-desc">
            {{$t('lnd.node.d2') }}
          </p>
        </li>
        <!-- <li class="section-stat__item">
          <h3 class="section-stat__list-val">
            {{$t('lnd.node.t3') }}
          </h3>
          <p class="section-stat__list-desc">
            {{$t('lnd.node.d3') }}
          </p>
        </li>
        <li class="section-stat__item">
          <h3 class="section-stat__list-val">
            {{$t('lnd.node.t4') }}
          </h3>
          <p class="section-stat__list-desc">
            {{$t('lnd.node.d4') }}
          </p> 
        </li>-->
      </ul>
    </div>
  </section>
</template>

<script>
import { api } from '@/api'

export default {
  name: 'LndNode',
  data() {
    return {
      onlineCount: 0,
      storageSize: 0,
    }
  },
  async mounted() {
    try {
      const { data } = await api.getNodesData()
      if (data) {
        // console.debug(
        //   '%c InternetSection.vue getNodesData: %s',
        //   'color:lime;',
        //   JSON.stringify(data, null, 4)
        // )

        this.onlineCount = data.online_count
        this.storageSize = data.storage_size
      }
    } catch (err) {
      console.debug(
        '%c InternetSection.vue getBalances ERROR:',
        'color:red;',
        err
      )
    }
  },
}
</script>
<!-- <script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class LndNode extends Vue {}
</script> -->
