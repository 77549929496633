<template>
  <section class="container section-horizon section">
    <div class="grid-two-columns">
      <h2 class="section__title">
        {{ $t('lnd.horizon.title') }}
      </h2>

      <div class="grid-two-columns__col-content">
        <p class="section__text">
          {{ $t('lnd.horizon.descr') }}
        </p>
      </div>
    </div>

    <div class="section-horizon__line-block line-block" ref="container">
      <ul class="line-block__items">
        <li
          v-for="(block, index) in blocks"
          :key="`block-${index}`"
          class="line-block__item"
          :class="{
            'line-block__item--active': index === currentBlock,
            'line-block__item_check': block.check,
          }"
          @click="setCurrentBlock(index)"
          @mouseenter="setCurrentBlock(index)"
        >
          <div class="line-block__item-title-wrapper">
            <p class="line-block__item-title">{{ block.title }}</p>
            <p class="line-block__item-text">{{ block.text }}</p>
          </div>
          <div class="line-block__point"></div>
          <p class="line-block__item-date">{{ block.date }}</p>
        </li>
      </ul>

      <div class="line-block__timeline">
        <p class="line-block__timeline-text">{{ $t('More soon') }}</p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'LndHorizon',
  data: () => ({
    blocks: [
      {
        title: 'Pre-orders of devices',
        text: 'Opening of the first pre-sales of DexNode.',
        date: 'Q1 2023',
        check: true,
      },
      {
        title: 'Listing of the DexNet token',
        text: 'Activation of a smart contract and listing.',
        date: 'Q4 2023',
        check: true,
      },
      {
        title: 'First DexNodes to the network',
        text: 'Activation of DexNode devices on the network.',
        date: 'Q4 2023',
        check: true,
      },
      {
        title: 'Decentralized payment system',
        text: 'Launch of a decentralized service with Mastercard.',
        date: 'Q2 2024',
        check: true,
      },
      {
        title: 'Closed testing of the DexCloud',
        text: 'Beta-testing of decentralized disk storage.',
        date: 'Now',
      },
      {
        title: 'Opening of DexMobile sales',
        text: 'Start of sales of eSIM cards.',
        date: 'Q3 2024',
      },
      {
        title: 'Blockchain deployment',
        text: 'Beta-testing of the DexNet blockchain.',
        date: 'Q4 2024',
      },
    ],
    currentBlock: null,
  }),
  methods: {
    setCurrentBlock(block) {
      this.currentBlock = block;
    }
  },
  mounted() {
    const currentBlock = this.$refs.currentBlock;
    const container = this.$refs.container;

    container.scroll({
      left: currentBlock.offsetLeft - ((window.innerWidth / 2) - (currentBlock.clientWidth / 2)),
      behavior: 'smooth'
    })
  },
}
</script>

<!-- <script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class LndHorizon extends Vue {}
</script> -->
